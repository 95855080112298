import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../assets/template/css/style.css';
import logo from '../assets/template/images/logo.svg';
import { Button, Card, Elevation, InputGroup, Label, Toast, H1 } from '@blueprintjs/core';
import axiosRequest from '../network/axiosRequest';

export const ForgotPassword = () => {
    let navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [emailDisable, setEmailDisable] = useState(false);
    const [submitDisable, setSubmitDisable] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const submitForgotPassword = async () => {
        if (email !== '' && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setErrorMessage('');
            setSuccessMessage('');
            enableDisableInputs(true);

            try {
                const res = await axiosRequest('/forgot_password', { email }, {});
                setSuccessMessage('Password reset email sent. Please check your inbox.');
                enableDisableInputs(false);
            } catch (e) {
                setErrorMessage('User not found.');
                enableDisableInputs(false);
            }
        } else {
            setErrorMessage('Invalid email address.');
        }
    };

    const enableDisableInputs = (bool: boolean | ((prevState: boolean) => boolean)) => {
        setEmailDisable(bool);
        setSubmitDisable(bool);
    };

    return (
        // <>
        //     <div className="layer"></div>
        //     <main className="page-center">
        //         <div className="row justify-content-center mb-5" style={{ display: 'block'}}>
        //             <a href="/"><img className="login-logo" src={logo} alt="logo" /></a>
        //         </div>
        //         <article className="sign-up">
        //             <form className="sign-up-form form" action="" method="">
        //                 <h1 className="sign-up__title">FORGOT PASSWORD</h1>
        //                 <p className="sign-up__subtitle">Enter your email to reset your password.</p>
        //                 {errorMessage && (
        //                     <Toast
        //                         timeout={5000}
        //                         onDismiss={() => setErrorMessage('')}
        //                         message={errorMessage}
        //                     />
        //                 )}
        //                 {successMessage && (
        //                     <Toast
        //                         icon='tick'
        //                         intent='success'
        //                         timeout={5000}
        //                         onDismiss={() => setSuccessMessage('')}
        //                         message={successMessage}
        //                     />
        //                 )}
        //                 <label className="form-label-wrapper">
        //                     <p className="form-label">Email</p>
        //                     <input className="form-input" type="email" placeholder="Enter your email" id='formControlLg' disabled={emailDisable} value={email} onChange={(e) => setEmail(e.target.value)} required />
        //                 </label>
        //                 <button className="form-btn primary-default-btn transparent-btn" onClick={submitForgotPassword} disabled={submitDisable}>Submit</button>
        //             </form>
        //         </article>
        //     </main>
        // </>
        <div className="authWrapper">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="fxt-form-logo">
                <Link to="/"><img src={logo} className="img-fluid" /></Link>
              </div>
              <div className="fxt-content">
                <div className="fxt-form-head text-center">
                  <h4>FORGOT PASSWORD</h4>
                  <p>Enter your email to reset your password.</p>
                  {errorMessage && (
                        <Toast
                            timeout={500000}
                            onDismiss={() => setErrorMessage('')}
                            message={errorMessage}
                        />
                    )}
                     {successMessage && (
                            <Toast
                                icon='tick'
                                intent='success'
                                timeout={5000}
                                onDismiss={() => setSuccessMessage('')}
                                message={successMessage}
                            />
                        )}
                </div>
                <form>
                  <div className="form-group mb-3">
                    <label htmlFor="email" className="form-label">Email Address</label>
                    <input type="text" id="email" className="form-control" placeholder="Enter Email Address"  disabled={emailDisable} value={email} onChange={(e) => setEmail(e.target.value)}/>
                  </div>

                
                  <button type='button' className="btn btn-primary primary-filled-button w-100 py-2" onClick={submitForgotPassword} disabled={submitDisable}>Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
    
      </div>
    );
};

export default ForgotPassword;
