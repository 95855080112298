import React, { useEffect, useState } from 'react';
import { Button } from '@blueprintjs/core';
import { axiosRequestGet, axiosRequest } from '../../network/axiosRequest';
import { useAuthUser } from 'react-auth-kit';
import { Link, useLocation, useNavigate } from "react-router-dom";
import '../../assets/template/css/subscription.css';
import '../../assets/template/css/fonts.css';
import '../../assets/template/css/bootstrap.min.css';
import Header from '../../components/header';
import Loader from '../../components/loader';
import Swal from 'sweetalert2';
import { AxiosError } from 'axios';

interface TopupPlan {
  id: number;
  stripe_plan_id: string;
  stripe_product_id: string;
  name: string;
  price: number;
  token_points: number | null;
}

const TopupCredit: React.FC = () => {
  const navigate = useNavigate();
  const auth = useAuthUser();
  const authData = auth();
  const userId = authData?.user_id;
  const token = auth()?.token;
  const [loading, setLoading] = useState(true);
  const location = useLocation(); 
  const [error, setError] = useState<string | null>(null);
  const [topupPlans, setTopupPlans] = useState<TopupPlan[]>([]);
  const [activeTopup, setActiveTopup] = useState<any>(null);

  useEffect(() => {
    fetchTopupPlans();
  }, [token]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const status = searchParams.get('status');

    if (status === 'success') {
      Swal.fire({
        title: 'Payment Successful!',
        text: 'Your top-up payment has been successfully processed.',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
        },
        confirmButtonText: 'OK'
      });
    } else if (status === 'cancel') {
      Swal.fire({
        title: 'Payment Canceled',
        text: 'Your payment was canceled.',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
        },
        confirmButtonText: 'OK'
      });
    }
  }, [location.search]);

  const fetchTopupPlans = async () => {
    try {
      const authData = auth();
  
      if (!authData || !authData.token) {
        throw new Error('User is not authenticated.');
      }
  
      const headers = {
        Authorization: `Bearer ${authData.token}`,
        "Content-Type": "application/json",
      };
  
      // Fetch top-up plans
      const plansResponse = await axiosRequestGet('/user/list_top_up_plans', headers);
      setTopupPlans(plansResponse.plans || []);
  
  
      setLoading(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        Swal.fire({
          title: 'Error',
          text: 'Failed to load top-up plans. Please try again later.',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
          },
          confirmButtonText: 'OK'
        });
      } else {
        console.error('Unexpected error:', error);
        Swal.fire({
          title: 'Unexpected Error',
          text: 'An unexpected error occurred. Please try again later.',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
          },
          confirmButtonText: 'OK'
        });
      }
      setLoading(false);
    }
  };

  const handleTopup = async (planId: number, tokenPoints: number | null) => {
    try {
      const authData = auth();
      const headers = {
        Authorization: `Bearer ${authData?.token}`,
        "Content-Type": "application/json",
      };

      const responseData = await axiosRequest('/create-Topup-checkout-session', {
        plan_id: planId,
        user_id: userId,
        token_points: tokenPoints,
      }, headers);

      if (responseData && responseData.url) {
        window.location.href = responseData.url;
      } else {
        throw new Error('Unable to redirect to payment.');
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Failed to process top-up. Please try again later.',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
        },
        confirmButtonText: 'OK'
      });
    }
  };

  const breadcrumbs = [
    { label: 'Dashboard', url: '/dashboard' },
    { label: 'Top-Up Plans' },
  ];

  return (
    <section className="main-right-wrapper">
      <Header pageTitle="Top-Up Plans" breadcrumbs={breadcrumbs} enable={true} />
      <div className="main-right-content">
        <div className="container-fluid p-relative">
          <div className="row wlcom_bx mb-3 d-md-none d-block">
            <div className="col-12">
              <div className="page-title-box">
                <h2 className="page-title">Top-Up Plans</h2>
                <div className="breadcrumb">
                  {breadcrumbs.map((breadcrumb:any, index:any) => (
                    <span key={index}>
                      {breadcrumb.url ? (
                        <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                      ) : (
                        <span>{breadcrumb.label}</span>
                      )}
                      {index < breadcrumbs.length - 1 && <span className="separator"> / </span>}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {loading ? ( 
            <Loader />
          ) : topupPlans.length > 0 ? (
            <div className="pricing-plans">
              {topupPlans.map((plan) => (
                <div 
                  key={plan.id} 
                  className="pricing-card basic"
                >
                  <div className="heading">
                    <h4>{plan.name}</h4>
                    <p>for Credit Purchase</p>
                  </div>
                  
                  <p className="price">${plan.price}</p>
                  <p className="mb-0 font-light-muted">
                    Credit Points: {plan.token_points ?? 'N/A'}
                  </p><br />
                  
                  <Button 
                    className="btn btn-primary primary-filled-button py-2 mx-auto" 
                    style={{padding: '10px 95px'}} 
                    onClick={() => handleTopup(plan.id, plan.token_points)}
                  >
                    Top-up
                  </Button>
               
                </div>
              ))}
            </div>
          ) : (
            <div className="col-12 text-center">
              <p>No top-up plans available.</p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default TopupCredit;
