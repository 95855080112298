import React, { useState, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/template/images/logo-icon.svg';
import logo2 from '../assets/template/images/logo_txt.svg';
import { axiosRequestGet } from '../network/axiosRequest'; // Import your axios request method
import { useAuthUser } from 'react-auth-kit';
import './sidebar.css';
import feather from 'feather-icons';
interface Categories {
  id: number;
  name: string;
}

const Sidebar: React.FC = () => {
  const location = useLocation();
  const [categories, setCategories] = useState<Categories[]>([]);
  const [isCopyHubOpen, setIsCopyHubOpen] = useState(true); // Set default to open
  const auth = useAuthUser();
  const token = useMemo(() => auth()?.token, [auth]);
  // Check if the current path matches or is nested under the link's path
  const isActive = (path: string) => {
    return location.pathname.startsWith(path);
  };

  // Fetch categories from the server
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        };
        const response = await axiosRequestGet('/admin/categories', headers); // Adjust the endpoint as needed
        setCategories(response); // Assuming response is an array of categories
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, [token]); 

  useEffect(() => {
    feather.replace(); // Initialize Feather icons
  }, []);

  // Toggle the dropdown
  const toggleCopyHub = () => {
    setIsCopyHubOpen(!isCopyHubOpen);
  };

  return (
    <aside className="main-sidebar sidebar" id="sidebar">
      <div className="logo d-flex align-items-start justify-content-between">
        <div className="d-flex align-items-start">
          <img src={logo} className="img-fluid" alt="Logo Icon" />
          <img src={logo2} className="img-fluid ms-2 w-75 logo_txt" alt="Logo Text" />
        </div>
        <button className="btn close_btn px-0 d-lg-none d-block">
          <i data-feather="x"></i>
        </button>
      </div>
      <div className="sidebar-inner">
        <ul className="sidebar-menu">
          <li className={`sidebar-menu-item ${isActive('/dashboard') || isActive('/profile-credit') || isActive('/profile-edit') || isActive('/contact-us') ? 'active' : ''}`}>
            <Link to="/dashboard" className="sidebar-link">
              <span className="icon-holder">
                <i data-feather="grid"></i>
              </span>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>
          <li className={`sidebar-menu-item ${isActive('/user-avatar') || isActive('/create-avatar') || isActive('/edit-avatar') ? 'active' : ''}`}>
            <Link to="/user-avatar" className="sidebar-link">
              <span className="icon-holder">
                <i data-feather="command"></i>
              </span>
              <span className="menu-title">Ideal Client</span>
            </Link>
          </li>
          <li className={`sidebar-menu-item ${isActive('/copy-hub') || isActive('/copycategory-hub')? 'active' : ''}`}>
          <Link to="/copycategory-hub">
            <span onClick={toggleCopyHub} className="sidebar-link" style={{ cursor: 'pointer' }}>
              <div className="d-flex" style={{justifyContent: 'space-between', width: '100%'}}>
                <div>
                  <span className="icon-holder">
                    <i data-feather="command"></i>
                  </span>
                  <span className="menu-title">Copy Hub</span>
                </div>
                <div>
                    <span data-feather="chevron-down"></span> {/* Add an arrow icon */}
                </div>
              </div>
              
              
            </span>
            {isCopyHubOpen && (
              <ul className="dropdowns-menu">
                {categories.length > 0 ? (
                  categories.map(category => (
                    <li key={category.id}>
                      <Link to={`/copy-hub/${category.id}`} className="dropdowns-item">
                      <span className="icon-holder">
                    <i data-feather="command"></i>
                  </span>
                  <span className="menu-title">{category.name}</span>
                      </Link>
                    </li>
                  ))
                ) : (
                  <li></li> // Message when no categories are fetched
                )}
              </ul>
            )}
          </Link>
          </li>
          <li className={`sidebar-menu-item ${isActive('/list-history') || isActive('/history-output') ? 'active' : ''}`}>
            <Link to="/list-history" className="sidebar-link">
              <span className="icon-holder">
                <i data-feather="folder"></i>
              </span>
              <span className="menu-title">My Copies</span>
            </Link>
          </li>
          <li className={`sidebar-menu-item ${isActive('/contact-us') ? 'active' : ''}`}>
            <Link to="/contact-us" className="sidebar-link">
              <span className="icon-holder">
                <i data-feather="help-circle"></i>
              </span>
              <span className="menu-title">Feedback</span>
            </Link>
          </li>
          
          <li className={`sidebar-menu-item ${isActive('/guide') ? 'active' : ''}`}>
            <Link to="/guide" className="sidebar-link">
              <span className="icon-holder">
                <i data-feather="book-open"></i>
              </span>
              <span className="menu-title">Guide</span>
            </Link>
          </li>
          {/* <li className={`sidebar-menu-item ${isActive('/listpayment') ? 'active' : ''}`}>
            <Link to="/listpayment" className="sidebar-link">
              <span className="icon-holder">
                <i data-feather="credit-card"></i>
              </span>
              <span className="menu-title">Payment</span>
            </Link>
          </li> */}
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;

// import React, { useState, useEffect } from 'react';
// import { Link, useLocation } from 'react-router-dom';
// import logo from '../assets/template/images/logo-icon.svg';
// import logo2 from '../assets/template/images/logo_txt.svg';
// import { axiosRequestGet } from '../network/axiosRequest'; // Import your axios request method

// interface Categories {
//   id: number;
//   name: string;
// }

// const Sidebar: React.FC = () => {
//   const location = useLocation();
//   const [categories, setCategories] = useState<Categories[]>([]);
//   const [isCopyHubOpen, setIsCopyHubOpen] = useState(true); // Set default to open

//   // Check if the current path matches or is nested under the link's path
//   const isActive = (path: string) => {
//     return location.pathname.startsWith(path);
//   };

//   // Fetch categories from the server
//   useEffect(() => {
//     const fetchCategories = async () => {
//       try {
//         const response = await axiosRequestGet('/admin/categories', {}); // Adjust the endpoint as needed
//         setCategories(response); // Assuming response is an array of categories
//       } catch (error) {
//         console.error('Error fetching categories:', error);
//       }
//     };

//     fetchCategories();
//   }, []);

//   // Toggle the dropdown
//   const toggleCopyHub = () => {
//     setIsCopyHubOpen(!isCopyHubOpen);
//   };

//   return (
//     <aside className="main-sidebar sidebar" id="sidebar">
//       <div className="logo d-flex align-items-start justify-content-between">
//         <div className="d-flex align-items-start">
//           <img src={logo} className="img-fluid" alt="Logo Icon" />
//           <img src={logo2} className="img-fluid ms-2 w-75 logo_txt" alt="Logo Text" />
//         </div>
//         <button className="btn close_btn px-0 d-lg-none d-block">
//           <i data-feather="x"></i>
//         </button>
//       </div>
//       <div className="sidebar-inner">
//         <ul className="sidebar-menu">
//           <li className={`sidebar-menu-item ${isActive('/dashboard') || isActive('/profile-credit') || isActive('/profile-edit') || isActive('/contact-us') ? 'active' : ''}`}>
//             <Link to="/dashboard" className="sidebar-link">
//               <span className="icon-holder">
//                 <i data-feather="grid"></i>
//               </span>
//               <span className="menu-title">Dashboard</span>
//             </Link>
//           </li>
//           <li className={`sidebar-menu-item ${isActive('/user-avatar') || isActive('/create-avatar') || isActive('/edit-avatar') ? 'active' : ''}`}>
//             <Link to="/user-avatar" className="sidebar-link">
//               <span className="icon-holder">
//                 <i data-feather="command"></i>
//               </span>
//               <span className="menu-title">Ideal Client</span>
//             </Link>
//           </li>
//           {/* Collapsible Copy Hub Section */}
//           <li className={`sidebar-menu-item ${isActive('/copy-hub') || isActive('/create-template') || isActive('/template-detail') ? 'active' : ''}`}>
//             <button onClick={toggleCopyHub} className="sidebar-link" style={{ cursor: 'pointer' }}>
//               <span className="icon-holder">
//                 <i data-feather="command"></i>
//               </span>
//               <span className="menu-title">Copy Hub</span>
//               <i className={`arrow ${isCopyHubOpen ? 'up' : 'down'}`}></i> {/* Add an arrow icon */}
//             </button>
//             {isCopyHubOpen && (
//               <ul className="dropdown-menu">
//                 {categories.map(category => (
//                   <li key={category.id}>
//                     <Link to={`/copy-hub/${category.id}`} className="sidebar-link">
//                       <span className="menu-title">{category.name}</span>
//                       {/* If you have template count, you can show it here */}
//                       {/* <span className="template-count">({category.templateCount || 0} templates)</span> */}
//                     </Link>
//                   </li>
//                 ))}
//               </ul>
//             )}
//           </li>
//           <li className={`sidebar-menu-item ${isActive('/list-history') || isActive('/history-output') ? 'active' : ''}`}>
//             <Link to="/list-history" className="sidebar-link">
//               <span className="icon-holder">
//                 <i data-feather="folder"></i>
//               </span>
//               <span className="menu-title">My Copies</span>
//             </Link>
//           </li>
//           <li className={`sidebar-menu-item ${isActive('/contact-us') ? 'active' : ''}`}>
//             <Link to="/contact-us" className="sidebar-link">
//               <span className="icon-holder">
//                 <i data-feather="help-circle"></i>
//               </span>
//               <span className="menu-title">Feedback</span>
//             </Link>
//           </li>
//           <li className={`sidebar-menu-item ${isActive('/guide') ? 'active' : ''}`}>
//             <Link to="/guide" className="sidebar-link">
//               <span className="icon-holder">
//                 <i data-feather="book-open"></i>
//               </span>
//               <span className="menu-title">Guide</span>
//             </Link>
//           </li>
//         </ul>
//       </div>
//     </aside>
//   );
// };

// export default Sidebar;
