import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { axiosRequestGet } from '../../network/axiosRequest';
import { useAuthUser } from 'react-auth-kit';
import '../../assets/template/css/style.css';
import Header from '../../components/header';
import Loader from '../../components/loader';
import feather from 'feather-icons';

interface Category {
  id: number;
  name: string;
  subtitle: string;
  created_at_category: string;
  updated_at_category: string;
}



const CategoryList: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [filteredCategories, setFilteredCategories] = useState<Category[]>([]);
  const [visibleCategories, setVisibleCategories] = useState<Category[]>([]);

  const [loading, setLoading] = useState(true);

  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsToShow, setItemsToShow] = useState(9); // Number of items to show at a time
  const auth = useAuthUser();

  const token = useMemo(() => auth()?.token, [auth]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!token) {
          throw new Error('User is not authenticated.');
        }

        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        };

        const categoriesResponse = await axiosRequestGet('/admin/categories', headers);
        setCategories(categoriesResponse);
        setFilteredCategories(categoriesResponse);
        setVisibleCategories(categoriesResponse.slice(0, itemsToShow)); // Show initial 9 categories


      } catch (error) {
        console.error('Failed to fetch data:', error);
        setError('Failed to load data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);

  useEffect(() => {
    const results = categories.filter(category =>
      category.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCategories(results);
    setVisibleCategories(results.slice(0, itemsToShow)); // Update visible categories based on search
  }, [searchTerm, categories, itemsToShow]);

  useEffect(() => {
    feather.replace(); // Initialize Feather icons
  }, []);

  const handleLoadMore = () => {
    setItemsToShow(prevCount => prevCount + 9); // Load next 9 categories
  };

  const breadcrumbs = [
    { label: 'Dashboard', url: '/dashboard' },
    { label: 'Copy Hub' },
  ];

  return (
    <section className="main-right-wrapper">
      <Header pageTitle="Copy Hub" breadcrumbs={breadcrumbs} enable={true} />
      <div className="main-right-content">
        <div className="container-fluid">
          <div className="row wlcom_bx mb-3 d-md-none d-block">
            <div className="col-12">
              <div className="page-title-box">
                <h2 className="page-title">Copy Hub</h2>
                <div className="breadcrumb">
                  {breadcrumbs.map((breadcrumb: any, index: any) => (
                    <span key={index}>
                      {breadcrumb.url ? (
                        <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                      ) : (
                        <span>{breadcrumb.label}</span>
                      )}
                      {index < breadcrumbs.length - 1 && <span className="separator"> / </span>}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {loading ? <><Loader /></> :
            <>
              <div className="d-flex align-items-center mb-2">
                <div className="font18 font600">Available Categories</div>
              </div>
              <div className="row g-4">
                {visibleCategories.length > 0 ? (
                  visibleCategories.map((category) => (
                    <div key={category.id} className="col-md-6 col-lg-4">
                      <Link to={`/copy-hub/${category.id}`} className="avatar-card-item" style={{ textDecoration: 'none' }}>
                        <div className="icon-box-circle">
                          <i data-feather="command" style={{ color: '#F05A25'}}></i>
                        </div>
                        <h4>{category.name}</h4>
                        <p className="mb-0 font-light-muted">{category.subtitle}</p>
                      </Link>
                    </div>
                  ))
                ) : (
                  <p>No categories available.</p>
                )}
              </div>
              {visibleCategories.length < filteredCategories.length && (
                <div className="col-lg-12 mb-4 text-center">
                  <button className="btn btn-primary primary-outlined-button py-2 px-4" onClick={handleLoadMore}>
                    Load More
                  </button>
                </div>
              )}
            </>
          }
        </div>
      </div>
    </section>
  );
};

export default CategoryList;
