import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import '../assets/template/css/style.css';
import logo from '../assets/template/images/logo.svg';
import { Button, Toast } from '@blueprintjs/core';
import axiosRequest from '../network/axiosRequest';

export const ResetPassword = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newPasswordDisable, setNewPasswordDisable] = useState(false);
    const [confirmPasswordDisable, setConfirmPasswordDisable] = useState(false);
    const [resetDisable, setResetDisable] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    // Extract token from URL
    const query = new URLSearchParams(location.search);
    const token = query.get('token');

    useEffect(() => {
        if (!token) {
            setErrorMessage('Token not found. Please try the reset password process again.');
            setResetDisable(true);
        }
    }, [token]);

    const submitResetPassword = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if (newPassword === confirmPassword && newPassword !== '') {
            setErrorMessage('');
            enableDisableInputs(true);

            try {
                await axiosRequest('/reset_password', { token, new_password: newPassword }, {});
                setSuccessMessage('Password reset successful. Redirecting to login page in 2s...');
                setErrorMessage('');
                // Delay navigation by 10 seconds
                setTimeout(() => {
                    navigate('/login');
                }, 2000);
            } catch (e) {
                setErrorMessage('Invalid or expired token.');
                enableDisableInputs(false);
            }
        } else {
            setErrorMessage('Passwords do not match.');
        }
    };

    const enableDisableInputs = (bool: boolean | ((prevState: boolean) => boolean)) => {
        setNewPasswordDisable(bool);
        setConfirmPasswordDisable(bool);
        setResetDisable(bool);
    };

    return (
        // <>
        //     <div className="layer"></div>
        //     <main className="page-center">
        //         <div className="row justify-content-center mb-5" style={{ display: 'block'}}>
        //             <a href="/"><img className="login-logo" src={logo} alt="logo" /></a>
        //         </div>
        //         <article className="sign-up">
        //             <form className="sign-up-form form" onSubmit={submitResetPassword}>
        //                 <h1 className="sign-up__title">RESET PASSWORD</h1>
        //                 <p className="sign-up__subtitle">Please enter your new password.</p>
        //                 {errorMessage && (
        //                     <Toast
        //                         icon='error'
        //                         intent='danger'
        //                         timeout={5000}
        //                         onDismiss={() => setErrorMessage('')}
        //                         message={errorMessage}
        //                     />
        //                 )}
        //                 {successMessage && (
        //                     <Toast
        //                         icon='tick'
        //                         intent='success'
        //                         timeout={10000}
        //                         onDismiss={() => setSuccessMessage('')}
        //                         message={successMessage}
        //                     />
        //                 )}
        //                 <label className="form-label-wrapper">
        //                     <p className="form-label">New Password</p>
        //                     <input className="form-input" type="password" placeholder="Enter your new password" id='formControlLg' disabled={newPasswordDisable} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
        //                 </label>
        //                 <label className="form-label-wrapper">
        //                     <p className="form-label">Confirm New Password</p>
        //                     <input className="form-input" type="password" placeholder="Confirm your new password" id='formControlLg' disabled={confirmPasswordDisable} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
        //                 </label>
        //                 <button className="form-btn primary-default-btn transparent-btn" type="submit" disabled={resetDisable}>Reset Password</button>
        //             </form>
        //         </article>
        //     </main>
        // </>
         <div className="authWrapper">
         <div className="container">
           <div className="row justify-content-center align-items-center">
             <div className="col-md-8 col-lg-6 col-xl-5">
               <div className="fxt-form-logo">
                 <Link to="/"><img src={logo} className="img-fluid" /></Link>
               </div>
               <div className="fxt-content">
                 <div className="fxt-form-head text-center">
                   <h4>RESET PASSWORD</h4>
                   <p>Please enter your new password.</p>
                   {errorMessage && (
                         <Toast
                             timeout={500000}
                             onDismiss={() => setErrorMessage('')}
                             message={errorMessage}
                         />
                     )}
                     {successMessage && (
                            <Toast
                                icon='tick'
                                intent='success'
                                timeout={10000}
                                onDismiss={() => setSuccessMessage('')}
                                message={successMessage}
                            />
                        )}
                 </div>
                 <form onSubmit={submitResetPassword}>
                  
                   <div className="form-group mb-3">
                     <label htmlFor="password" className="form-label">New Password</label>
                     <input type="password" className="form-control" placeholder="Enter Password" id="password"  disabled={newPasswordDisable} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
                   </div>
                   <div className="form-group mb-3">
                     <label htmlFor="password" className="form-label">Confirm Password</label>
                     <input type="password" className="form-control" placeholder="Enter Password" id="password" disabled={confirmPasswordDisable} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                   </div>
                   
                   <button className="btn btn-primary primary-filled-button w-100 py-2" type="submit" disabled={resetDisable}>Reset Password </button>
                 </form>
               </div>
             </div>
           </div>
         </div>
     
       </div>
    );
};

export default ResetPassword;
