import React, { useEffect, useState, useMemo } from "react";
import { axiosRequestGet } from '../network/axiosRequest';
import { useAuthUser } from 'react-auth-kit';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import '../assets/template/css/style.css';
import Header from '../components/header';
import { Link } from "react-router-dom";
import feather from 'feather-icons';
import eye from '../assets/template/images/eye.svg';

interface RequestData {
  first_name: string;
  last_name: string;
  avatar_name: string;
  title: string;
  user_output: string;
  created_at: string;  // Unified field for both requests and avatars
  type: 'request' | 'avatar';
  user_avatar_id?: number; 
  id?: number;
}

interface VideoContent {
  id: number;
  avatar: string;
  template: string;
  dashboard: string;
  help: string;
  created_at_video: string;
  updated_at_video: string;
}

interface Announcement {
  id: number;
  title: string;
  description: string;
  image: string;
  status: number;
  created_at_announcement: string;
  updated_at_announcement: string;
  position: number;
}
interface UserDetails {
  id: number;
  first_name: string;
  last_name: string;
  profile_image: string;
}

interface DashboardData {
  total_points: number;
  avatars_created_this_month: number;
  user_avatar_history_this_month: number;
  avatar_limit: number;
  template_limit: number;
  monthly_avatars_created: Array<{
    month: string;
    avatars_created: number;
  }>;
  monthly_templates_created: Array<{
    month: string;
    templates_created: number;
  }>;
}

const Dashboard: React.FC = () => {
  const [dashboardData, setDashboardData] = useState<DashboardData | null>(null);
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [requests, setRequests] = useState<RequestData[]>([]);
  const [videoContent, setVideoContent] = useState<VideoContent | null>(null);
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const auth = useAuthUser();
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const token = useMemo(() => auth()?.token, [auth]);

  useEffect(() => {
  
  
    fetchData();
  }, [token]);
  const fetchData = async () => {
    try {
      if (!token) {
        throw new Error('User is not authenticated.');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };

      const userDetails = await axiosRequestGet('/users/details', headers);
      setUserDetails(userDetails as UserDetails);

      const dashboardResponse = await axiosRequestGet('/user/dashboardData', headers);
      setDashboardData(dashboardResponse as DashboardData);

      const combinedResponse = await axiosRequestGet('/users/recent/documents', headers);
      setRequests(combinedResponse as RequestData[]);

      const videoContentResponse = await axiosRequestGet('/admin/video_content', headers);
      setVideoContent(videoContentResponse[0] as VideoContent);

      // Ensure announcementsResponse is correctly formatted
      const announcementsResponse = await axiosRequestGet('/user/announcement', headers);
      if (Array.isArray(announcementsResponse.announcements)) {
        setAnnouncements(announcementsResponse.announcements as Announcement[]);
      } else {
        throw new Error('Announcements data is not in expected format');
      }

    } catch (error) {
      console.error('Failed to fetch data:', error);
      setError('Failed to load data.');
    } finally {
      setLoading(false);
    }  
  };



  const handleToggle = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const sortedAnnouncements = [...announcements].sort((a, b) => a.position - b.position);

  // Combine data for the chart
  const getMonthlyAvatarAndTemplateData = () => {
    return dashboardData?.monthly_avatars_created.map((item, index) => ({
      month: item.month,
      avatars_created: item.avatars_created,
      templates_created: dashboardData?.monthly_templates_created[index]?.templates_created || 0,
    })) || [];
  };

  const monthlyData = getMonthlyAvatarAndTemplateData();

  useEffect(() => {
    feather.replace(); // Initialize Feather icons
  }, []);
  
  const breadcrumbs = [
    { label: 'Dashboard'},
    
  ];

  return (
    <section className="main-right-wrapper">
<Header
  pageTitle={`What’s up, ${userDetails?.first_name ?? ''}! `}
  breadcrumbs={breadcrumbs}
  enable={false}
/>



    <div className="main-right-content">
     
      <div className="container-fluid">
        <div className="row wlcom_bx mb-3 d-md-none d-block">
          <div className="col-12">
            <div className="page-title-box">
              <h2 className="page-title">What’s up, {auth()?.first_name}!</h2>
              <p className="mb-0">Let’s start writing copy and getting clients!</p>
            </div>
          </div>
        </div>

        <div className="row pb-xl-4 pb-0">
          {/* <div className="col-lg-4 col-md-6 col-12">
            <div className="dashboard-widget-card">
              <div className="widget-icon-circle lightGrayBox">
                <span data-feather="clipboard"></span>
              </div>
              <div className="dashboard-widget-card-info">
                <h4 className="mb-0 fs-5 mt-1">{dashboardData?.total_points || 0}</h4>
                <p className="mb-0 font14 font-muted">Total Credit Points</p>
              </div>
            </div>
          </div> */}
          <div className="col-lg-6 col-md-6 col-12">
            <div className="dashboard-widget-card">
              <div className="widget-icon-circle lightPrimaryBox">
                <span data-feather="briefcase"></span>
              </div>
              <div className="dashboard-widget-card-info">
                <h4 className="mb-0 fs-5 mt-1"> {`${dashboardData?.avatars_created_this_month} / ${dashboardData?.avatar_limit}`}  <span className="font13 font-muted font400">This Month</span></h4>
                <p className="mb-0 font14 font-muted">Ideal Client Created</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="dashboard-widget-card">
              <div className="widget-icon-circle lightSuccessBox">
                <span data-feather="briefcase"></span>
              </div>
              <div className="dashboard-widget-card-info">
                <h4 className="mb-0 fs-5 mt-1">{`${dashboardData?.user_avatar_history_this_month} / ${dashboardData?.template_limit}`} <span className="font13 font-muted font400">This Month</span></h4>
                <p className="mb-0 font14 font-muted">Template Created</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row g-3 g-xl-5 pb-5">
          <div className="col-lg-6">
            <div className="d-flex align-items-center mb-2">
              <div className="font18 font600">Reports</div>
            </div>
            <div className="border rounded reports-wrapper">
              {/* Bar chart for avatars and templates created */}
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={monthlyData}>
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="avatars_created" fill="#f05a25" name="Ideal Clients Created" />
                  <Bar dataKey="templates_created" fill="#4e4e4e" name="Templates Created" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="d-flex align-items-center mb-2">
              <div className="font18 font600">Recent Documents</div>
              <div className="ms-auto font13">
              <Link to="/user-avatar" className="ms-auto font13" style={{marginRight: '15px'}}>View Ideal Client</Link>
        
              <Link to="/list-history" className="ms-auto font13">View Template</Link>
              </div>
              
            </div>
            <div className="border rounded reports-wrapper overflow-hidden">
              <table className="row-border">
                <thead>
                  <tr>
                    <th>Documents</th>
                    <th>Type</th>
                    <th>Created</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {requests.slice(0, 5).map((request, index) => (
                    <tr key={index}>
                      <td>{request.title || request.avatar_name}</td>
                      <td>{request.type === 'request' ? 'Template' : 'Ideal Client'}</td>
                      <td>{request.created_at}</td>
                      <td>
                        <div className="d-flex align-items-center justify-content-center gap-3">
                          <Link
                            to={
                              request.type === 'request'
                                ? `/history-output/${request.id}`
                                : `/edit-avatar/${request.user_avatar_id}` 
                            }
                            className="font-muted"
                          >
                            
                            <img src={eye} />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>

              </table>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="d-flex align-items-center mb-2">
              <div className="font18 font600">How to use</div>
            </div>
            <div className="howtousevideo">
              {videoContent ? (
                <iframe
                  style={{ margin: "20px 0" }}
                  width="100%"
                  height="315"
                  src={videoContent.dashboard}
                  title="Dashboard Video Content"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              ) : (
                <p>No video content available</p>
              )}
            </div>
          </div>
          <div className="col-lg-6" style={{maxHeight: '400px', overflowY: 'auto'}}>
            <div className="d-flex align-items-center mb-2">
              <div className="font18 font600">Announcement</div>
            </div>
            <div className="accordion" id="announcementAccordion">
              {sortedAnnouncements.map((announcement) => (
                <React.Fragment key={announcement.id}>
                  <div
                    className={`border-bottom p-3 d-flex gap-3 align-items-center ${expandedIndex === announcement.id ? 'expanded' : ''}`}
                  >
                    <div className="tech-image">
                      <picture>
                        <source srcSet={announcement.image} type="image/webp" />
                        <img
                          src={announcement.image}
                          alt={announcement.title}
                          style={{ width: '100px', height: '50px', objectFit: 'cover' }}
                        />
                      </picture>
                    </div>
                    <div className="flex-grow-1">
                      <h4 className="font16 font600 mb-1">{announcement.title}</h4>
                      <p className="font-muted font14 mb-0">
                        {announcement.updated_at_announcement} <span className="mx-3">|</span> 04:00 PM
                      </p>
                    </div>
                    <div>
                      <button
                        className="faq-toggle-btn"
                        onClick={() => handleToggle(announcement.id)}
                      >
                        <span className={`arrow ${expandedIndex === announcement.id ? 'up' : 'down'}`}></span>
                      </button>
                    </div>
                  </div>
                  {expandedIndex === announcement.id && (
                    <div className="faq-content mt-2">
                      <p>{announcement.description}</p>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>


        </div>
      </div>
    </div>
    </section>
  );
};

export default Dashboard;
