import React, { useEffect, useState, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Icon } from '@blueprintjs/core';
import { IconName } from '@blueprintjs/icons';
import { axiosRequestGet } from '../../network/axiosRequest';
import { useAuthUser } from 'react-auth-kit';
import '../../assets/template/css/style.css';
import Header from '../../components/header';
import Loader from '../../components/loader';

interface Template {
  id: number;
  title: string;
  subtitle: string;
  icon: IconName;
  form_fields: number;
  status: number;
  created_at_template: string;
  updated_at_template: string;
}

interface VideoContent {
  id: number;
  avatar: string;
  template: string;
  dashboard: string;
  help: string;
  created_at_video: string;
  updated_at_video: string;
}

interface UserAvatarHistory {
  id: number;
  user_id: number;
  user_avatar_id: number;
  avatar_name: string;
  template_id: number;
  title: string;
  user_output: string;
  created_at_history: string;
  updated_at_history: string | null;
}

interface UserDetails {
  id: number;
  first_name: string;
  last_name: string;
  profile_image: string;
}

interface FAQ {
  id: number;
  title: string;
  description: string;
  status: number;
  created_at_templatefaq: string;
  updated_at_templatefaq: string;
  position: number;
}

const TemplateList: React.FC = () => {
  const [templates, setTemplates] = useState<Template[]>([]);
  const [filteredTemplates, setFilteredTemplates] = useState<Template[]>([]);
  const [visibleTemplates, setVisibleTemplates] = useState<Template[]>([]);
  const [videoContent, setVideoContent] = useState<VideoContent | null>(null);
  const [userAvatarHistory, setUserAvatarHistory] = useState<UserAvatarHistory[]>([]);
  const [loading, setLoading] = useState(true);
  const [faqList, setFaqList] = useState<FAQ[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsToShow, setItemsToShow] = useState(9); // Number of items to show at a time
  const { category_id } = useParams<{ category_id: string }>();
  const auth = useAuthUser();

  const token = useMemo(() => auth()?.token, [auth]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!token) {
          throw new Error('User is not authenticated.');
        }

        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        };

        const userDetails = await axiosRequestGet('/users/details', headers);
        setUserDetails(userDetails as UserDetails);

        const templatesResponse = await axiosRequestGet(`/user/category/templates/${category_id}`, headers);
        setTemplates(templatesResponse);
        setFilteredTemplates(templatesResponse);
        setVisibleTemplates(templatesResponse.slice(0, itemsToShow)); // Show initial 9 templates

        const videoContentResponse = await axiosRequestGet('/admin/video_content', headers);
        setVideoContent(videoContentResponse[0] as VideoContent);

        const userAvatarHistoryResponse = await axiosRequestGet('/users/template/userAvatarHistoryByUser', headers);
        setUserAvatarHistory(userAvatarHistoryResponse);
        // Fetch FAQ data
        const faqResponse = await axiosRequestGet('/api/users/faq/templatefaq', headers);
        setFaqList(faqResponse.templatefaq as FAQ[]);


      } catch (error) {
        console.error('Failed to fetch data:', error);
        setError('Failed to load data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token,, category_id, itemsToShow]);

  useEffect(() => {
    const results = templates.filter(template =>
      template.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredTemplates(results);
    setVisibleTemplates(results.slice(0, itemsToShow)); // Update visible templates based on search
  }, [searchTerm, templates, itemsToShow]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleLoadMore = () => {
    setItemsToShow(prevCount => prevCount + 9); // Load next 9 templates
  };



  const breadcrumbs = [
    { label: 'Dashboard', url: '/dashboard' },
    { label: 'Copy Hub' },
  ];

  return (
    <section className="main-right-wrapper">
      <Header pageTitle="Copy Hub" breadcrumbs={breadcrumbs} enable={true} />
      <div className="main-right-content">
        <div className="container-fluid">
          <div className="row wlcom_bx mb-3 d-md-none d-block">
            <div className="col-12">
              <div className="page-title-box">
                <h2 className="page-title">Copy Hub</h2>
                <div className="breadcrumb">
                  {breadcrumbs.map((breadcrumb: any, index: any) => (
                    <span key={index}>
                      {breadcrumb.url ? (
                        <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                      ) : (
                        <span>{breadcrumb.label}</span>
                      )}
                      {index < breadcrumbs.length - 1 && <span className="separator"> / </span>}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {loading ?  <><Loader/></> :
          <>
          
          {userAvatarHistory.length > 0 ? (
            <>
            <div className="d-flex align-items-center mb-2">
            <div className="font18 font600">Available Templates</div>
          </div>
              <div className="booking-filter mb-4">
                <div className="row g-3">
                  <div className="col-sm-8">
                    <div className="position-relative startIconInputField">
                      <input
                        type="text"
                        id="search"
                        name="search"
                        placeholder="Search..."
                        className="form-control"
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                      <span className="iconPosition">
                        <i data-feather="search"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-4">
                {visibleTemplates.length > 0 ? (
                  visibleTemplates.map((template) => (
                    <div key={template.id} className="col-md-6 col-lg-4">
                      <Link to={`/template-detail/${template.id}`} className="avatar-card-item" style={{ textDecoration: 'none' }}>
                        <div className="icon-box-circle">
                          {template.icon && <Icon icon={template.icon} size={21} color="#F05A25" />}
                        </div>
                        <h4>{template.title}</h4>
                        <p className="mb-0 font-light-muted">{template.subtitle}</p>
                      </Link>
                    </div>
                  ))
                ) : (
                  <p>No templates available.</p>
                )}
              </div>
              <br /><br /><br />
              {visibleTemplates.length < filteredTemplates.length && (
                <div className="col-lg-12 mb-4 text-center">
                  <button className="btn btn-primary primary-outlined-button py-2 px-4" onClick={handleLoadMore}>
                    Load More
                  </button>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="bg-light border rounded px-3 py-5 mb-4 d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <h4 className="text-black mb-2">Hi, {userDetails?.first_name ?? ''} {userDetails?.last_name ?? ''}</h4>
                  <p className="font-light-muted mb-2">You have not created any Ideal Clients yet.</p>
                  <Link className="btn btn-primary primary-filled-button py-2 mx-auto" to="/create-template">
                    Create Template <span className="fa fa-plus"></span>
                  </Link>
                </div>
              </div>
              <div className="d-flex align-items-center mb-2">
                <div className="font18 font600">Available Templates</div>
                <Link to="/create-template" className="ms-auto font13">View All</Link>
              </div>
              <div className="row g-4">
                {visibleTemplates.slice(0,6).length > 0 ? (
                  visibleTemplates.slice(0,6).map((template) => (
                    <div key={template.id} className="col-md-6 col-lg-4">
                      <Link to={`/template-detail/${template.id}`} className="avatar-card-item" style={{ textDecoration: 'none' }}>
                        <div className="icon-box-circle">
                          {template.icon && <Icon icon={template.icon} size={21} color="#F05A25" />}
                        </div>
                        <h4>{template.title}</h4>
                        <p className="mb-0 font-light-muted">{template.subtitle}</p>
                      </Link>
                    </div>
                  ))
                ) : (
                  <p>No templates available.</p>
                )}
              </div>
              {videoContent && (
                <div className="row g-4 mt-4">
                  <div className="col-lg-7">
                    <div className="d-flex align-items-center mb-2">
                      <div className="font18 font600">How to use</div>
                    </div>
                    <div className="howtousevideo">
                      <iframe
                        width="100%"
                        height="315"
                        src={videoContent.template}
                        title="Video tutorial"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              )}
              {/* FAQ Section */}
              <div className="col-lg-12">
                      <div className="d-flex align-items-center mb-2">
                        <div className="font18 font600">FAQ</div>
                      </div>
                      <div className="accordion accordion-flush discover-accordion-section mb-4" id="accordionFlushExample">
                        {faqList.length > 0 ? (
                          faqList.map((faq) => (
                            <div key={faq.id} className="accordion-item opened">
                              <h2 className="accordion-header">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#flush-collapse${faq.id}`}
                                  aria-expanded="false"
                                  aria-controls={`flush-collapse${faq.id}`}
                                >
                                  {faq.title}
                                </button>
                              </h2>
                              <div
                                id={`flush-collapse${faq.id}`}
                                className="accordion-collapse collapse show"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div className="accordion-body">
                                  <p>{faq.description}</p>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="text-center">
                            <p>No FAQ content available.</p>
                          </div>
                        )}
                      </div>
                    </div>
              {visibleTemplates.length < filteredTemplates.length && (
                
                <div className="col-lg-12 mb-4 text-center">
                  <button className="btn btn-primary primary-outlined-button py-2 px-4" onClick={handleLoadMore}>
                    Load More
                  </button>
                </div>
              )}
            </>
          )}
          </>
          }
        </div>
      </div>
    </section>
  );
};

export default TemplateList;

// import React, { useEffect, useState, useMemo } from 'react';
// import { Link, useParams } from 'react-router-dom'; // Import useParams to get category_id from URL
// import { Icon } from '@blueprintjs/core';
// import { IconName } from '@blueprintjs/icons';
// import { axiosRequestGet } from '../../network/axiosRequest';
// import { useAuthUser } from 'react-auth-kit';
// import '../../assets/template/css/style.css';
// import Header from '../../components/header';
// import Loader from '../../components/loader';

// interface Template {
//   id: number;
//   title: string;
//   subtitle: string;
//   icon: IconName;
//   form_fields: number;
//   status: number;
//   created_at_template: string;
//   updated_at_template: string;
// }

// const TemplateList: React.FC = () => {
//   const [templates, setTemplates] = useState<Template[]>([]);
//   const [filteredTemplates, setFilteredTemplates] = useState<Template[]>([]);
//   const [visibleTemplates, setVisibleTemplates] = useState<Template[]>([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState<string | null>(null);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [itemsToShow, setItemsToShow] = useState(9); // Number of items to show at a time
//   const auth = useAuthUser();

//   const { category_id } = useParams<{ category_id: string }>(); // Extract category_id from the URL
//   const token = useMemo(() => auth()?.token, [auth]);

//   useEffect(() => {
//     const fetchTemplatesByCategory = async () => {
//       try {
//         if (!token) {
//           throw new Error('User is not authenticated.');
//         }

//         const headers = {
//           Authorization: `Bearer ${token}`,
//           'Content-Type': 'application/json',
//         };

//         // Fetch templates by category_id
//         const templatesResponse = await axiosRequestGet(`/user/category/templates/${category_id}`, headers);
//         setTemplates(templatesResponse);
//         setFilteredTemplates(templatesResponse);
//         setVisibleTemplates(templatesResponse.slice(0, itemsToShow)); // Show initial 9 templates

//       } catch (error) {
//         console.error('Failed to fetch templates:', error);
//         setError('Failed to load templates.');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchTemplatesByCategory();
//   }, [token, category_id, itemsToShow]); // Add category_id as a dependency

//   useEffect(() => {
//     const results = templates.filter(template =>
//       template.title.toLowerCase().includes(searchTerm.toLowerCase())
//     );
//     setFilteredTemplates(results);
//     setVisibleTemplates(results.slice(0, itemsToShow)); // Update visible templates based on search
//   }, [searchTerm, templates, itemsToShow]);

//   const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(e.target.value);
//   };

//   const handleLoadMore = () => {
//     setItemsToShow(prevCount => prevCount + 9); // Load next 9 templates
//   };

//   const breadcrumbs = [
//     { label: 'Dashboard', url: '/dashboard' },
//     { label: 'Templates' },
//   ];

//   return (
//     <section className="main-right-wrapper">
//       <Header pageTitle="Templates" breadcrumbs={breadcrumbs} enable={true} />
//       <div className="main-right-content">
//         <div className="container-fluid">
//           <div className="row wlcom_bx mb-3 d-md-none d-block">
//             <div className="col-12">
//               <div className="page-title-box">
//                 <h2 className="page-title">Templates</h2>
//                 <div className="breadcrumb">
//                   {breadcrumbs.map((breadcrumb: any, index: any) => (
//                     <span key={index}>
//                       {breadcrumb.url ? (
//                         <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
//                       ) : (
//                         <span>{breadcrumb.label}</span>
//                       )}
//                       {index < breadcrumbs.length - 1 && <span className="separator"> / </span>}
//                     </span>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           </div>
//           {loading ? <Loader /> : (
//             <>
//               <div className="d-flex align-items-center mb-2">
//                 <div className="font18 font600">Available Templates</div>
//               </div>
//               <div className="booking-filter mb-4">
//                 <div className="row g-3">
//                   <div className="col-sm-8">
//                     <div className="position-relative startIconInputField">
//                       <input
//                         type="text"
//                         id="search"
//                         name="search"
//                         placeholder="Search..."
//                         className="form-control"
//                         value={searchTerm}
//                         onChange={handleSearchChange}
//                       />
//                       <span className="iconPosition">
//                         <i data-feather="search"></i>
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="row g-4">
//                 {visibleTemplates.length > 0 ? (
//                   visibleTemplates.map((template) => (
//                     <div key={template.id} className="col-md-6 col-lg-4">
//                       <Link to={`/template-detail/${template.id}`} className="avatar-card-item" style={{ textDecoration: 'none' }}>
//                         <div className="icon-box-circle">
//                           {template.icon && <Icon icon={template.icon} size={21} color="#F05A25" />}
//                         </div>
//                         <h4>{template.title}</h4>
//                         <p className="mb-0 font-light-muted">{template.subtitle}</p>
//                       </Link>
//                     </div>
//                   ))
//                 ) : (
//                   <p>No templates available.</p>
//                 )}
//               </div>
//               <br /><br /><br />
//               {visibleTemplates.length < filteredTemplates.length && (
//                 <div className="col-lg-12 mb-4 text-center">
//                   <button className="btn btn-primary primary-outlined-button py-2 px-4" onClick={handleLoadMore}>
//                     Load More
//                   </button>
//                 </div>
//               )}
//             </>
//           )}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default TemplateList;
