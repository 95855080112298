import React, { useEffect, useState } from 'react';
import { Button, Card } from '@blueprintjs/core';
import { axiosRequestGet } from '../../network/axiosRequest';
import { useAuthUser } from 'react-auth-kit';
import { Link, useNavigate } from "react-router-dom";
import '../../assets/template/css/style.css';
import '../../assets/template/css/fonts.css';
import '../../assets/template/css/bootstrap.min.css';
import icon from '../../assets/template/images/command.svg';
import Header from '../../components/header';
import Loader from '../../components/loader';

interface Avatar {
  created_at: string | number | Date;
  id: number;
  avatar_name: string;
  user_id: number;
  form_field_id: number;
  status: number;
  created_at_avatar: string;
  updated_at_avatar: string;
  idealclient: string;
}

interface VideoContent {
  id: number;
  avatar: string;
  template: string;
  dashboard: string;
  help: string;
  created_at_video: string;
  updated_at_video: string;
}

interface UserDetails {
  id: number;
  first_name: string;
  last_name: string;
  profile_image: string;
}

interface FAQ {
  id: number;
  title: string;
  description: string;
  status: number;
  created_at_avatarfaq: string;
  updated_at_avatarfaq: string;
  position: number;
}



const ProfileAvatar: React.FC = () => {
  const navigate = useNavigate();
  const auth = useAuthUser();
  const [avatars, setAvatars] = useState<Avatar[]>([]);
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [filteredAvatars, setFilteredAvatars] = useState<Avatar[]>([]);
  const [videoContent, setVideoContent] = useState<VideoContent | null>(null);
  const [faqList, setFaqList] = useState<FAQ[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [visibleCount, setVisibleCount] = useState(9); // Number of avatars to display initially

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const authData = auth();

        if (!authData || !authData.token) {
          throw new Error('User is not authenticated.');
        }

        const headers = {
          Authorization: `Bearer ${authData.token}`,
          "Content-Type": "application/json",
        };

        const userDetails = await axiosRequestGet('/users/details', headers);
        setUserDetails(userDetails as UserDetails);

        // Fetch avatars
        const avatarsResponse = await axiosRequestGet(`/user/user_avatars`, headers);
        setAvatars(avatarsResponse);
        setFilteredAvatars(avatarsResponse.slice(0, visibleCount)); // Display the initial 9 avatars

        // Fetch video content
        const videoContentResponse = await axiosRequestGet('/admin/video_content', headers);
        setVideoContent(videoContentResponse[0] as VideoContent);

        // Fetch FAQ data
        const faqResponse = await axiosRequestGet('/api/users/faq/avatarfaq', headers);
        setFaqList(faqResponse.avatarfaq as FAQ[]);
        setLoading(false);
      } catch (error) {
        setError('Failed to load Ideal Clients.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [visibleCount]);

  useEffect(() => {
    const results = avatars.filter(avatar =>
      avatar.avatar_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredAvatars(results.slice(0, visibleCount)); // Filter and limit to visible count
  }, [searchTerm, avatars, visibleCount]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleLoadMore = () => {
    setVisibleCount(prevCount => prevCount + 9); // Load 9 more avatars
  };



  const breadcrumbs = [
    { label: 'Dashboard', url: '/dashboard' },
    { label: 'Ideal Client' },
  ];

  return (
    <section className="main-right-wrapper">
      <Header pageTitle="Ideal Client" breadcrumbs={breadcrumbs} enable={true} />
      <div className="main-right-content">
        <div className="container-fluid p-relative">
          <div className="row wlcom_bx mb-3 d-md-none d-block">
            <div className="col-12">
              <div className="page-title-box">
                <h2 className="page-title">Ideal Client</h2>
                <div className="breadcrumb">
                  {breadcrumbs.map((breadcrumb:any, index:any) => (
                    <span key={index}>
                      {breadcrumb.url ? (
                        <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                      ) : (
                        <span>{breadcrumb.label}</span>
                      )}
                      {index < breadcrumbs.length - 1 && <span className="separator"> / </span>}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {loading ?  <><Loader/></> :
          <>
       
          <div className="booking-filter mb-4">
            <div className="row g-3">
              <div className="col-sm-8">
                <div className="position-relative startIconInputField">
                  <input
                    type="text"
                    id="search"
                    name="search"
                    placeholder="Search..."
                    className="form-control"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <span className="iconPosition">
                    <i data-feather="search"></i>
                  </span>
                </div>
              </div>
              <div className="col-sm-4 text-sm-end">
                <Link className="btn btn-primary primary-filled-button py-2" to="/create-avatar">
                  Create Ideal Client
                </Link>
              </div>
            </div>
          </div>

          <div className="row g-4">
            {filteredAvatars.length > 0 ? (
              filteredAvatars.map((avatar) => (
                <div key={avatar.id} className="col-md-6 col-lg-4">
                  <Link className="avatar-card-item" to={`/edit-avatar/${avatar.id}`} style={{ textDecoration: 'none' }}>
                    <div className="icon-box-circle">
                      <img src={icon} alt="image" title="" />
                    </div>
                    <h4>{avatar.avatar_name}</h4>
                    <p className="mb-0 font-light-muted">
                      {avatar.idealclient}
                    </p>
                    <p className="mb-0 font-light-muted">
                      {avatar.created_at_avatar}
                    </p>
                  </Link>
                </div>
              ))
            ) : searchTerm ? (
              <div className="col-12 text-center">
                <p>No matched Ideal Clients available.</p>
              </div>
            ) : (
              <div className="main-right-content">
                <div className="container-fluid">
                  <div className="row wlcom_bx mb-3 d-md-none d-block">
                    <div className="col-12">
                      <div className="page-title-box">
                        <h2 className="page-title">Ideal Client</h2>
                        <div className="breadcrumb">
                          <Link to="/dashboard">Dashboard</Link>
                          <span className="separator">/</span>
                          <span>Ideal Client</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-light border rounded px-3 py-5 mb-4 d-flex align-items-center justify-content-center">
                    <div className="text-center">
                      <h4 className="text-black mb-2">Hi, {userDetails?.first_name ?? ''} {userDetails?.last_name ?? ''}</h4>
                      <p className="font-light-muted mb-2">You have not created any Ideal Clients yet.</p>
                      <Link className="btn btn-primary primary-filled-button py-2 mx-auto" to="/create-avatar">
                        Create Ideal Client
                      </Link>
                    </div>
                  </div>
                  <div className="row g-4">
                    <div className="col-lg-7">
                      <div className="d-flex align-items-center mb-2">
                        <div className="font18 font600">How to use</div>
                      </div>
                      <div className="howtousevideo">
                        {videoContent ? (
                          <iframe
                            style={{ margin: "20px 0" }}
                            width="100%"
                            height="410"
                            src={videoContent.avatar}
                            title="Dashboard Video"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                          ></iframe>
                        ) : (
                          <p>No video content available.</p>
                        )}
                        
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="d-flex align-items-center mb-2">
                        <div className="font18 font600">FAQ</div>
                      </div>
                      <div className="accordion accordion-flush discover-accordion-section mb-4" id="accordionFlushExample">
                        {faqList.length > 0 ? (
                          faqList.map((faq) => (
                            <div key={faq.id} className="accordion-item opened">
                              <h2 className="accordion-header">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#flush-collapse${faq.id}`}
                                  aria-expanded="false"
                                  aria-controls={`flush-collapse${faq.id}`}
                                >
                                  {faq.title}
                                </button>
                              </h2>
                              <div
                                id={`flush-collapse${faq.id}`}
                                className="accordion-collapse collapse show"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div className="accordion-body">
                                  <p>{faq.description}</p>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="text-center">
                            <p>No FAQ content available.</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <br /><br />
          {visibleCount < avatars.length && (
            <div className="row mt-4">
              <div className="col-12 text-center">
                <Button
                  className="btn btn-primary primary-outlined-button py-2 px-4"
                  onClick={handleLoadMore}
                >
                  Load More
                </Button>
              </div>
            </div>
          )}
          </>
          }
        </div>
      </div>
    </section>
  );
};

export default ProfileAvatar;
