// network/axiosRequest.ts
import axios from "axios";

const Baseurl = 'https://api.fckdev.com';

export const axiosRequestGet = async (url: string, header: any) => {
    try {
        const response = await axios.get(Baseurl + url, { headers: header });
        return response.data;
    } catch (error) {
        console.error('Error in axiosRequestGet:', error);
        throw error;
    }
};

// Other axios functions
export const axiosRequest = async (url: string, data: any, header: any) => {
    const response = await axios.post(Baseurl + url, data, { headers: header });
    return response.data;
};

export const axiosRequestPut = async (url: string, data: any, header: any) => {
    const response = await axios.put(Baseurl + url, data, { headers: header });
    return response.data;
};

export const axiosRequestDelete = async (url: string, header: any) => {
    const response = await axios.delete(Baseurl + url, { headers: header });
    return response.data;
};

export default axiosRequest;
