// import React, { useEffect, useState } from 'react';
// import { Button } from '@blueprintjs/core';
// import { useForm } from 'react-hook-form';
// import { useAuthUser } from 'react-auth-kit';
// import { axiosRequestGet, axiosRequest } from '../../network/axiosRequest';
// import { Link, useNavigate } from 'react-router-dom';
// import Swal from 'sweetalert2';
// import '../../assets/template/css/style.css';
// import Header from '../../components/header';

// interface FormField {
//   class: string;
//   inputType: string;
//   lenMsg: string;
//   name: string;
//   optional: boolean;
//   options: { key: string; value: string }[];
//   placeholder: string;
//   requiredMsg: string;
//   subtitle: string;
//   tag: string;
//   title: string;
//   value: string;
// }

// interface GroupData {
//   groupdata: FormField[];
//   groupname: string;
//   heading: string;
// }

// interface FieldData {
//   field: GroupData[];
//   id: number;
//   name: string;
//   status: number;
// }

// const CreateUserAvatar: React.FC = () => {
//   const { register, handleSubmit, formState: { errors } } = useForm();
//   const auth = useAuthUser();
//   const token = auth()?.token;
//   const navigate = useNavigate();
//   const [formFields, setFormFields] = useState<FieldData | null>(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState<string | null>(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const authData = auth();
//         if (!authData || !authData.token) {
//           throw new Error('User is not authenticated.');
//         }

//         const headers = {
//           Authorization: `Bearer ${authData.token}`,
//           'Content-Type': 'application/json',
//         };

//         // Fetch form fields
//         const formFieldsResponse = await axiosRequestGet('/user/form_field', headers);
//         const avatarField = formFieldsResponse.find((field: FieldData) => field.id === 15);
//         if (!avatarField) {
//           throw new Error('Ideal Client creation form field not found.');
//         }
//         setFormFields(avatarField);
//       } catch (error: unknown) {
//         if (error instanceof Error) {
//           console.error('Failed to fetch data:', error.message);
//           setError('Failed to load data.');
//         } else {
//           console.error('An unknown error occurred:', error);
//           setError('An unknown error occurred.');
//         }
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [token]);

//   const onSubmit = async (data: any) => {
//     console.log(data);
//     try {
//       // Show confirmation dialog before submitting
//       const result = await Swal.fire({
//         title: 'Are you sure?',
//         text: 'Do you want to create this Ideal Client?',
//         icon: 'warning',
//         showCancelButton: true,
//         customClass: {
//           confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
//           cancelButton: 'btn btn-danger danger-filled-button py-2 px-4',
//         },
//         confirmButtonText: 'Yes, create Ideal Client!',
//         cancelButtonText: 'Cancel',
//       });

//       if (result.isConfirmed) {
//         const authData = auth();
//         if (!authData || !authData.token) {
//           throw new Error('User is not authenticated.');
//         }

//         const headers = {
//           Authorization: `Bearer ${authData.token}`,
//           'Content-Type': 'application/json',
//         };

//         const { avatar_name, ...restData } = data;
//         const user_response = restData;
//         console.log(user_response);
//         const payload = {
//           avatar_name,
//           form_field_id: 15,
//           user_response,
//           status: 0,
//         };
//         console.log(payload);
//         const response = await axiosRequest('/user/user_avatar/create', payload, headers);
//         console.log('Ideal Client created successfully!', response);
//         Swal.fire({
//           title: 'Created!',
//           text: 'Your Ideal Client has been created.',
//           icon: 'success',
//           customClass: {
//             confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
//           },
//           confirmButtonText: 'Ok',
//         });
//         navigate('/user-avatar');
//       }
//     } catch (error: any) {
//       setLoading(false);
//       if (error.response && error.response.status === 403) {
//         Swal.fire({
//           icon: 'error',
//           title: 'Exceeded limit',
//           text: 'You have exceeded the maximum number of credits to generate Ideal Client, please contact admin or upgrade your plan.',
//           customClass: {
//             confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
//           },
//         });
//       } else {
//         Swal.fire({
//           icon: 'error',
//           title: 'Error',
//           text: error.message || 'An unexpected error occurred. Please try again.',
//           customClass: {
//             confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
//           },
//         });
//       }
//     }
//   };


//   const breadcrumbs = [
//     { label: 'Dashboard', url: '/dashboard' },
//     { label: 'Ideal Client', url: '/user-avatar' },
//     { label: 'Create Ideal Client' },
//   ];

//   return (
//     <section className="main-right-wrapper">
//     <Header pageTitle="Ideal Client" breadcrumbs={breadcrumbs} enable={true} />
//     <div className="main-right-content">
//       <div className="container-fluid">
//         <div className="row wlcom_bx mb-3 d-md-none d-block">
//           <div className="col-12">
//             <div className="page-title-box">
//               <h2 className="page-title">Ideal Client</h2>
//               <div className="breadcrumb">
//                                 {breadcrumbs.map((breadcrumb:any, index:any) => (
//                                     <span key={index}>
//                                         {breadcrumb.url ? (
//                                         <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
//                                         ) : (
//                                         <span>{breadcrumb.label}</span>
//                                         )}
//                                         {index < breadcrumbs.length - 1 && <span className="separator"> / </span>}
//                                     </span>
//                                     ))}
//             </div>
//             </div>
//           </div>
//         </div>
//         <div className="p-3 bg-light rounded mb-4">
//           <div className="bg-white border rounded p-3">
//             <form onSubmit={handleSubmit(onSubmit)}>
//               <div className="form-group mb-3">
//                 <label htmlFor="avatar_name" className="font15 mb-0 font500">
//                   Name of This Ideal Client
//                 </label>
//                 <p className="mb-2 font13 font-muted font300">
//                   e.g. Caleb / Jia Li / Jesica (This will act as your reference when it comes to using the copy templates)
//                 </p>
//                 <input
//                   type="text"
//                   id="avatar_name"
//                   className="form-control"
//                   placeholder=""
                 
//                   {...register('avatar_name', {
//                     required: 'Ideal Client name is required.',
//                   })}
//                 />
//                  {errors['avatar_name'] && (
//                           <p style={{ color: 'red', fontSize: '12px' }}>
//                             {errors['avatar_name']?.message?.toString() || `Ideal Client name is required`}
//                      </p>       
//                    )}
             
//               </div>
  
//               {formFields && formFields.field.map((group, groupIndex) => (
//                 <div key={groupIndex}>
//                   <h5>{group.heading}</h5>
//                   {group.groupdata.map((field, index) => (
//                     <div key={index} className="form-group mb-3">
//                       <label htmlFor={field.name} className="font15 mb-0 font500">
//                         {field.title}
//                       </label>
//                       <p className="mb-2 font13 font-muted font300">
//                         {field.subtitle}
//                       </p>

//                       {/* Handling different input types */}
//                       {field.tag === 'input' ? (
//                         <>
//                           {field.inputType === 'checkbox' ? (
//                             <div>
//                               <input
//                                 type="checkbox"
//                                 id={field.name}
//                                 className="form-check-input"
//                                 {...register(field.name, { required: field.optional ? false : field.requiredMsg })}
//                               />
//                               <label htmlFor={field.name} className="form-check-label">
//                                 {field.title}
//                               </label>
//                             </div>
//                           ) : field.inputType === 'radio' ? (
//                             <div>
//                               {field.options.map((option, idx) => (
//                                 <div key={idx} className="form-check">
//                                   <input
//                                     type="radio"
//                                     id={`${field.name}_${idx}`}
//                                     value={option.value}
//                                     className="form-check-input"
//                                     {...register(field.name, { required: field.optional ? false : field.requiredMsg })}
//                                   />
//                                   <label htmlFor={`${field.name}_${idx}`} className="form-check-label">
//                                     {option.key}
//                                   </label>
//                                 </div>
//                               ))}
//                             </div>
//                           ) : (
//                             <input
//                               type={field.inputType}
//                               id={field.name}
//                               className="form-control"
//                               placeholder=""
//                               {...register(field.name, { required: field.optional ? false : field.requiredMsg })}
//                             />
//                           )}

//                           {errors[field.name] && (
//                             <p style={{ color: 'red', fontSize: '12px' }}>
//                               {errors[field.name]?.message?.toString() || `${field.title} is required`}
//                             </p>
//                           )}

//                           {/* Hidden input for title */}
//                           <input
//                             type='hidden'
//                             className="form-control"
//                             placeholder=""
//                             value={field.title}
//                             {...register(field.name + '_title', { required: field.optional ? false : field.requiredMsg })}
//                           />
//                         </>
//                       ) : field.tag === 'select' ? (
//                         <>
//                           <select
//                             id={field.name}
//                             className="form-control"
//                             {...register(field.name, { required: field.optional ? false : field.requiredMsg })}
//                           >
//                             <option value="" disabled>Select an option</option>
//                             {field.options.map((option, idx) => (
//                               <option key={idx} value={option.value}>
//                                 {option.key}
//                               </option>
//                             ))}
//                           </select>

//                           {errors[field.name] && (
//                             <p style={{ color: 'red', fontSize: '12px' }}>
//                               {errors[field.name]?.message?.toString() || `${field.title} is required`}
//                             </p>
//                           )}

//                           {/* Hidden input for title */}
//                           <input
//                             type='hidden'
//                             className="form-control"
//                             placeholder=""
//                             value={field.title}
//                             {...register(field.name + '_title', { required: field.optional ? false : field.requiredMsg })}
//                           />
//                         </>
//                       ) : null}
//                     </div>
//                   ))}
//                 </div>
//               ))}

//               <div className="mb-3 text-end">
//                 <button
//                   type="submit"
//                   className="btn btn-primary primary-filled-button py-2 px-4"
//                 >
//                   Create Ideal Client
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </div>
//   </section>
  
//   );
// };

// export default CreateUserAvatar;


import React, { useEffect, useState } from 'react';
import { Button } from '@blueprintjs/core';
import { useForm } from 'react-hook-form';
import { useAuthUser } from 'react-auth-kit';
import { axiosRequestGet, axiosRequest } from '../../network/axiosRequest';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import '../../assets/template/css/style.css';
import Header from '../../components/header';
import Loader from '../../components/loader';
import TypingEffect from '../../components/typingeffect';

interface FormField {
  class: string;
  inputType: string;
  lenMsg: string;
  name: string;
  optional: boolean;
  options: { key: string; value: string }[];
  placeholder: string;
  requiredMsg: string;
  subtitle: string;
  tag: string;
  title: string;
  value: string;
  ai_assist: boolean;
  ai_assist_button_text: string;
  ai_assist_prompt: string;
}

interface GroupData {
  groupdata: FormField[];
  groupname: string;
  heading: string;
}

interface FieldData {
  field: GroupData[];
  id: number;
  name: string;
  status: number;
}

const CreateUserAvatar: React.FC = () => {
  const { register, handleSubmit, formState: { errors }, reset, setValue, trigger } = useForm();
  const auth = useAuthUser();
  const token = auth()?.token;
  const navigate = useNavigate();
  const [formFields, setFormFields] = useState<FieldData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(0); // Tracks current group/page
  const [formValues, setFormValues] = useState<{ [key: string]: any }>({}); // Store values for all fields
  const [gptResponse, setGptResponse] = useState('');
  const user_id = auth()?.user_id;
  const [isLoadingAiAssist, setIsLoadingAiAssist] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const authData = auth();
        if (!authData || !authData.token) {
          throw new Error('User is not authenticated.');
        }

        const headers = {
          Authorization: `Bearer ${authData.token}`,
          'Content-Type': 'application/json',
        };

        // Fetch form fields
        const formFieldsResponse = await axiosRequestGet('/user/form_field', headers);
        const avatarField = formFieldsResponse.find((field: FieldData) => field.id === 15);
        if (!avatarField) {
          throw new Error('Ideal Client creation form field not found.');
        }
        setFormFields(avatarField);
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error('Failed to fetch data:', error.message);
          setError('Failed to load data.');
        } else {
          console.error('An unknown error occurred:', error);
          setError('An unknown error occurred.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);


  const onSubmit = async (data: any) => {
    try {
      const authData = auth();
      if (!authData || !authData.token) {
        throw new Error('User is not authenticated.');
      }
  
      const headers = {
        Authorization: `Bearer ${authData.token}`,
        'Content-Type': 'application/json',
      };
  
      const { avatar_name, ...restData } = data;
      const user_response = restData;
      const payload = {
        avatar_name,
        form_field_id: 15,
        user_response,
        status: 0,
      };
      
      // Trigger Swal confirmation here
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to create this Ideal Client?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
          cancelButton: 'btn btn-danger danger-filled-button py-2 px-4',
        },
        confirmButtonText: 'Yes, create Ideal Client!',
        cancelButtonText: 'Cancel',
      });
  
      if (result.isConfirmed) {
        const response = await axiosRequest('/user/user_avatar/create', payload, headers);
        Swal.fire({
          title: 'Created!',
          text: 'Your Ideal Client has been created.',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
          },
          confirmButtonText: 'Ok',
        });
        navigate('/user-avatar');
      }
    } catch (error: any) {
      if (error.response && error.response.status === 403) {
        Swal.fire({
          icon: 'error',
          title: 'Exceeded limit',
          text: 'You have exceeded the maximum number of credits to generate Ideal Client, please contact admin or upgrade your plan.',
          customClass: {
            confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
          },
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message || 'An unexpected error occurred. Please try again.',
          customClass: {
            confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
          },
        });
      }
    }
  };
  
  // Submit button click handler
  const handleSubmitClick = () => {
    handleSubmit(onSubmit)(); // Call handleSubmit with onSubmit only when the button is clicked
  };
  
  // Form submission handler to prevent default behavior
  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const nextPage = async () => {
    // Trigger validation for current page before moving to the next page
    const valid = await trigger();
    if (valid) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const prevPage = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    const { value } = event.target;
    setFormValues({
      ...formValues,
      [fieldName]: value,
    });
  };
 
  
  // const handleAiAssist = async (field: FormField) => {
  //   setIsLoadingAiAssist(true);
  //   try {
  //     const userInput = formValues[field.name]; // Get user input for this field
  
  //     if (!userInput) {
  //       Swal.fire({
  //         icon: 'warning',
  //         title: 'Input required',
  //         text: `Please provide input for ${field.title}`,
  //         customClass: {
  //           confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
  //         },
  //       });
  //       return;
  //     }
  
  //     // Replace placeholders in the ai_assist_prompt
  //     let aiPrompt = field.ai_assist_prompt;
  //     aiPrompt = aiPrompt.replace(/{(.*?)}/g, (match, fieldName) => {
  //       // Replace with user input value or a default message
  //       return formValues[fieldName] || 'No data provided';
  //     });
  //     console.log(aiPrompt);
  //     const headers = {
  //       Authorization: `Bearer ${auth()?.token}`,
  //       'Content-Type': 'application/json',
  //     };
  
  //     // Send to ChatGPT API for processing
  //     const gptResponse = await axiosRequest(
  //       '/ai_assist/chatgpt/analyze', 
  //       { result_string: aiPrompt, user_id: user_id }, 
  //       headers
  //     );
      
  //     setGptResponse(gptResponse.response);
  
  //   } catch (error: any) {
  //     if (error.response && error.response.status === 411) {
  //       // Handle 411 error for insufficient credits
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Insufficient Credits',
  //         text: 'You have insufficient credits. Please top up to continue using AI Assist.',
  //         customClass: {
  //           confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
  //         },
  //       });
  //     } else {
  //       // Handle other errors
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Error',
  //         text: error.message || 'An unexpected error occurred while processing AI Assist.',
  //         customClass: {
  //           confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
  //         },
  //       });
  //     }
  //   } finally {
  //     setIsLoadingAiAssist(false); // Stop loading in both success and error cases
  //   }
  // };
  // Inside your component, update the handleAiAssist function:
const handleAiAssist = async (field: FormField) => {
  setIsLoadingAiAssist(true);
  try {
    // Use existing form values without triggering validation
    let aiPrompt = field.ai_assist_prompt;
    aiPrompt = aiPrompt.replace(/{(.*?)}/g, (match, fieldName) => {
      return formValues[fieldName] || 'No data provided';
    });
    console.log(aiPrompt);
    const headers = {
      Authorization: `Bearer ${auth()?.token}`,
      'Content-Type': 'application/json',
    };

    const gptResponse = await axiosRequest(
      '/ai_assist/chatgpt/analyze', 
      { result_string: aiPrompt, user_id: user_id }, 
      headers
    );
    
    setGptResponse(gptResponse.response);
  
  } catch (error: any) {
    if (error.response && error.response.status === 411) {
      Swal.fire({
        icon: 'error',
        title: 'Insufficient Credits',
        text: 'Please top up to continue using AI Assist.',
        customClass: {
          confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
        },
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'An unexpected error occurred.',
        customClass: {
          confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
        },
      });
    }
  } finally {
    setIsLoadingAiAssist(false);
  }
};


  const handleCopy = () => {
    if (!gptResponse) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No output to copy!',
        customClass: {
          confirmButton: 'btn btn-primary primary-filled-button py-2 px-4', // Custom class for the confirm button
          cancelButton: 'btn btn-danger danger-filled-button py-2 px-4'    // Custom class for the cancel button
        }
      });
      return;
    }

    // Copy the response text to the clipboard
    navigator.clipboard.writeText(gptResponse).then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Copied!',
        text: 'The output has been copied to your clipboard.',
        customClass: {
          confirmButton: 'btn btn-primary primary-filled-button py-2 px-4', // Custom class for the confirm button
          cancelButton: 'btn btn-danger danger-filled-button py-2 px-4'    // Custom class for the cancel button
        }
      });
    }).catch((error) => {
      console.error('Failed to copy text:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to copy the output. Please try again.',
        customClass: {
          confirmButton: 'btn btn-primary primary-filled-button py-2 px-4', // Custom class for the confirm button
          cancelButton: 'btn btn-danger danger-filled-button py-2 px-4'    // Custom class for the cancel button
        }
      });
    });
  };

  const breadcrumbs = [
    { label: 'Dashboard', url: '/dashboard' },
    { label: 'Ideal Client', url: '/user-avatar' },
    { label: 'Create Ideal Client' },
  ];

  return (
    <section className="main-right-wrapper">
      <Header pageTitle="Ideal Client" breadcrumbs={breadcrumbs} enable={true} />
      <div className="main-right-content">
        <div className="container-fluid">
          <div className="row wlcom_bx mb-3 d-md-none d-block">
            <div className="col-12">
              <div className="page-title-box">
                <h2 className="page-title">Ideal Client</h2>
                <div className="breadcrumb">
                  {breadcrumbs.map((breadcrumb: any, index: any) => (
                    <span key={index}>
                      {breadcrumb.url ? (
                        <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                      ) : (
                        <span>{breadcrumb.label}</span>
                      )}
                      {index < breadcrumbs.length - 1 && <span className="separator"> / </span>}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="p-3 bg-light rounded mb-4">
                <div className="bg-white border rounded p-3">
                  {loading && <p>Loading...</p>}
                  {error && <p>{error}</p>}

                  {formFields && (
                    <form onSubmit={handleFormSubmit}>
                      {/* Show the Ideal Client Name field only on the first page */}
                      {currentPage === 0 && (
                        <div className="form-group mb-3">
                          <label htmlFor="avatar_name" className="font15 mb-0 font500">
                            Name of This Ideal Client
                          </label>
                          <p className="mb-2 font13 font-muted font300">
                            e.g. Caleb / Jia Li / Jesica (This will act as your reference when it comes to using the copy templates)
                          </p>
                          <input
                            type="text"
                            id="avatar_name"
                            className="form-control"
                            {...register('avatar_name', {
                              required: 'Ideal Client name is required.',
                            })}
                          />
                          {errors['avatar_name'] && (
                            <p style={{ color: 'red', fontSize: '12px' }}>
                              {errors['avatar_name']?.message?.toString() || `Ideal Client name is required`}
                            </p>
                          )}
                        </div>
                      )}

                      {/* {formFields.field[currentPage] && (
                        <div>
                          <h5>{formFields.field[currentPage].heading}</h5>
                          {formFields.field[currentPage].groupdata.map((field, index) => (
                            <div key={index} className="form-group mb-3">
                              <div className="d-flex justify-content-between align-items-center">
                                <div>
                                  <label htmlFor={field.name} className="font15 mb-0 font500">
                                    {field.title}
                                  </label>
                                  <p className="mb-2 font13 font-muted font300">{field.subtitle}</p>
                                </div>
                                {field.ai_assist && (
                                  <button
                                    type="button"
                                    className="btn btn-primary primary-filled-button py-2 px-4"
                                    style ={{ height: '25px' }}
                                    onClick={() => handleAiAssist(field)}
                                  >
                                    {field.ai_assist_button_text || 'AI Assist'}
                                  </button>
                                )}
                              </div>

                              {field.tag === 'input' && field.inputType === 'text' && (
                                <>
                                  <input
                                    type="text"
                                    id={field.name}
                                    className="form-control"
                                    {...register(field.name, {
                                      required: !field.optional && field.requiredMsg,
                                      onChange: (e) => handleChange(e, field.name)
                                    })}
                                    value={formValues[field.name] || ''} // Bind value to form state
                                  />
                                  {errors[field.name] && (
                                    <p style={{ color: 'red', fontSize: '12px' }}>
                                      {errors[field.name]?.message?.toString() || `${field.title} is required`}
                                    </p>
                                  )}
                                </>
                              )}
                            </div>
                          ))}
                        </div>
                      )} */}
                          {formFields.field[currentPage] && (
                          <div>
                            {formFields.field[currentPage].groupdata.map((field, index) => (
                              <div key={index} className="form-group mb-3">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <label htmlFor={field.name} className="font15 mb-0 font500">
                                      {field.title}
                                    </label>
                                    <p className="mb-2 font13 font-muted font300">{field.subtitle}</p>
                                  </div>
                                  {field.ai_assist && (
                                    <button
                                      type="button"
                                      className="btn btn-primary primary-filled-button py-2 px-4"
                                      style ={{ height: '25px' }}
                                      onClick={() => handleAiAssist(field)}
                                    >
                                      {field.ai_assist_button_text || 'AI Assist'}
                                    </button>
                                  )}
                                </div>

                                {field.tag === 'input' && field.inputType === 'text' && (
                                  <>
                                    <input
                                      type="text"
                                      id={field.name}
                                      className="form-control"
                                      {...register(field.name, {
                                        required: !field.optional && field.requiredMsg,
                                        onChange: (e) => handleChange(e, field.name)
                                      })}
                                      value={formValues[field.name] || ''} // Bind value to form state
                                    />
                                    {errors[field.name] && (
                                      <p style={{ color: 'red', fontSize: '12px' }}>
                                        {errors[field.name]?.message?.toString() || `${field.title} is required`}
                                      </p>
                                    )}
                                  </>
                                )}
                              </div>
                            ))}
                          </div>
                        )}

                      {/* Back and Next buttons */}
                      <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                        {currentPage > 0 && (
                          <button type="button" className="btn btn-primary primary-filled-button py-2 px-46" onClick={prevPage}>
                            Back
                          </button>
                        )}
                        {currentPage < formFields.field.length - 1 ? (
                          <button type="button" className="btn btn-primary primary-filled-button py-2 px-46" onClick={nextPage}>
                            Next
                          </button>
                        ) : (
                          <button type="submit" onClick={handleSubmitClick} className="btn btn-primary primary-filled-button py-2 px-46">
                            Submit
                          </button>
                        )}
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="p-3 bg-light rounded mb-4">
                <div className="d-flex align-items-center mb-2">
                  <h5 className="text-center">Output</h5>
                  <div className="ms-auto font15 d-flex gap-3">
                    <Link to="#" onClick={handleCopy}>
                      <i className="fa fa-clone"></i>
                    </Link>
                  </div>
                </div>
                {isLoadingAiAssist ? ( // Show loader when AI assist is loading
                  <Loader />
                ) : loading ? (
                  <Loader />
                ) : (
                  <>
                    {gptResponse ? (
                      <TypingEffect text={gptResponse} speed={10} />
                    ) : (
                      <div>No Output available.</div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default CreateUserAvatar;




  // const onSubmit = async (data: any) => {
  //   console.log(data);
  //   try {
  //     const result = await Swal.fire({
  //       title: 'Are you sure?',
  //       text: 'Do you want to create this Ideal Client?',
  //       icon: 'warning',
  //       showCancelButton: true,
  //       customClass: {
  //         confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
  //         cancelButton: 'btn btn-danger danger-filled-button py-2 px-4',
  //       },
  //       confirmButtonText: 'Yes, create Ideal Client!',
  //       cancelButtonText: 'Cancel',
  //     });

  //     if (result.isConfirmed) {
  //       const authData = auth();
  //       if (!authData || !authData.token) {
  //         throw new Error('User is not authenticated.');
  //       }

  //       const headers = {
  //         Authorization: `Bearer ${authData.token}`,
  //         'Content-Type': 'application/json',
  //       };

  //       const { avatar_name, ...restData } = data;
  //       const user_response = restData;
  //       console.log(user_response);
  //       const payload = {
  //         avatar_name,
  //         form_field_id: 15,
  //         user_response,
  //         status: 0,
  //       };
  //       console.log(payload);
  //       const response = await axiosRequest('/user/user_avatar/create', payload, headers);
  //       console.log('Ideal Client created successfully!', response);
  //       Swal.fire({
  //         title: 'Created!',
  //         text: 'Your Ideal Client has been created.',
  //         icon: 'success',
  //         customClass: {
  //           confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
  //         },
  //         confirmButtonText: 'Ok',
  //       });
  //       navigate('/user-avatar');
  //     }
  //   } catch (error: any) {
  //     setLoading(false);
  //     if (error.response && error.response.status === 403) {
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Exceeded limit',
  //         text: 'You have exceeded the maximum number of credits to generate Ideal Client, please contact admin or upgrade your plan.',
  //         customClass: {
  //           confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
  //         },
  //       });
  //     } else {
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Error',
  //         text: error.message || 'An unexpected error occurred. Please try again.',
  //         customClass: {
  //           confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
  //         },
  //       });
  //     }
  //   }
  // };




   // const handleAiAssist = async (field: FormField) => {
  //   setIsLoadingAiAssist(true);
  //   try {
  //     const userInput = formValues[field.name]; // Get user input for this field
  
  //     if (!userInput) {
  //       Swal.fire({
  //         icon: 'warning',
  //         title: 'Input required',
  //         text: `Please provide input for ${field.title}`,
  //         customClass: {
  //           confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
  //         },
  //       });
  //       return;
  //     }
  
  //     // Replace placeholders in the ai_assist_prompt
  //     let aiPrompt = field.ai_assist_prompt;
  //     aiPrompt = aiPrompt.replace(/{(.*?)}/g, (match, fieldName) => {
  //       // Replace with user input value or a default message
  //       return formValues[fieldName] || 'No data provided';
  //     });
  //     console.log(aiPrompt);
  //     const headers = {
  //       Authorization: `Bearer ${auth()?.token}`,
  //       'Content-Type': 'application/json',
  //     };
  
  //     // Send to ChatGPT API for processing
  //     const gptResponse = await axiosRequest('/ai_assist/chatgpt/analyze', { result_string: aiPrompt, user_id: user_id }, headers);
      
  //     setGptResponse(gptResponse.response);
  
  
  //   } catch (error: any) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Error',
  //       text: error.message || 'An unexpected error occurred while processing AI Assist.',
  //       customClass: {
  //         confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
  //       },
  //     });
  //     }finally {
  //       setIsLoadingAiAssist(false); // Stop loading in both success and error cases
  //   }
  // };