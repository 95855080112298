
import React from 'react';
import '../assets/template/css/loader.css';

const Loader: React.FC = () => {
  return (
    <div className="loader">
      <div className="spinner"></div><br /><br /><p style={{paddingLeft: '20px', margin: '0', fontWeight: '600'}}>Please wait for a few moments as we work to save you hours of your time</p>
   
    </div>
    
  );
};

export default Loader;
