import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';


import { AuthProvider } from 'react-auth-kit';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

        <AuthProvider authType = {'localstorage'}
                  authName={'_auth'}                
                  cookieDomain={window.location.hostname}
                  cookieSecure={window.location.protocol === "https:"}>
    <App />
    </AuthProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
