import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import '../../assets/template/css/style.css';
import Header from '../../components/header';
import { axiosRequestGet } from '../../network/axiosRequest';
import { useAuthUser } from 'react-auth-kit';
import icon from '../../assets/template/images/command.svg';

interface UserAvatarHistory {
  id: number;
  user_id: number;
  user_avatar_id: number;
  avatar_name: string;
  template_id: number;
  title: string;
  user_output: string;
  created_at_history: string;
  updated_at_history: string | null;
  status: number;
}

interface Template {
  id: number;
  title: string;
  subtitle: string;
}

const ListHistory: React.FC = () => {
  const [userAvatarHistory, setUserAvatarHistory] = useState<UserAvatarHistory[]>([]);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [selectedAvatar, setSelectedAvatar] = useState<number | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<number | null>(null);
  const [liked, setLiked] = useState<number | null>(null);
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [visibleItems, setVisibleItems] = useState<UserAvatarHistory[]>([]);
  const [distinctAvatars, setDistinctAvatars] = useState<UserAvatarHistory[]>([]);
  const [itemsToShow, setItemsToShow] = useState(5);
  const auth = useAuthUser();

  const token = useMemo(() => auth()?.token, [auth]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!token) {
          throw new Error('User is not authenticated.');
        }
  
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        };
  
        // Fetch user avatar history and distinct avatars
        const response = await axiosRequestGet('/search/users/template/userAvatarHistoryByUser', headers);
        
        // Set the entire history for visible items
        setUserAvatarHistory(response.entries);
        setVisibleItems(response.entries.slice(0, itemsToShow));
  
        // Set distinct avatars for the dropdown
        setDistinctAvatars(response.distinct_avatars);
  
        // Fetch templates
        const templatesResponse = await axiosRequestGet('/admin/template', headers);
        setTemplates(templatesResponse);
  
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setError('Failed to load data.');
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [token, itemsToShow]);
  


  useEffect(() => {
    const results = userAvatarHistory.filter(item =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setVisibleItems(results.slice(0, itemsToShow));
  }, [searchTerm, userAvatarHistory, itemsToShow]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleLoadMore = () => {
    setItemsToShow(prevCount => prevCount + 5);
  };

  const handleFilterChange = async () => {
    try {
      if (!token) {
        throw new Error('User is not authenticated.');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };

      // Construct query parameters
      const params = new URLSearchParams();
      if (selectedAvatar) params.append('user_avatar_id', selectedAvatar.toString());
      if (selectedTemplate) params.append('template_id', selectedTemplate.toString());
      if (liked !== null) params.append('liked', liked.toString());
      if (status !== null) params.append('status', status.toString());

      // Fetch filtered data
      const filteredData = await axiosRequestGet(`/users/template/userAvatarHistoryByUser?${params.toString()}`, headers);
      
      console.log('Filtered Data:', filteredData); // Log the filtered data for debugging
      setUserAvatarHistory(filteredData);
      setVisibleItems(filteredData.slice(0, itemsToShow));
    } catch (error) {
      console.error('Failed to filter data:', error);
      setError('Failed to filter data.');
    }
  };


  // Filter out duplicate avatars based on avatar_name
  const uniqueAvatars = useMemo(() => {
    const avatarsMap = new Map();
    userAvatarHistory.forEach((avatar) => {
      if (!avatarsMap.has(avatar.avatar_name)) {
        avatarsMap.set(avatar.avatar_name, avatar);
      }
    });
    return Array.from(avatarsMap.values());
  }, [userAvatarHistory]);

  const breadcrumbs = [
    { label: 'Dashboard', url: '/dashboard' },
    { label: 'My Copies' },
  ];

  return (
    <section className="main-right-wrapper">
      <Header pageTitle="My Copies" breadcrumbs={breadcrumbs} enable={true} />
      <div className="main-right-content">
        <div className="container-fluid pb-5">
          <div className="row wlcom_bx mb-3 d-md-none d-block">
            <div className="col-12">
              <div className="page-title-box">
                <h2 className="page-title">My Copies</h2>
                <div className="breadcrumb">
                  {breadcrumbs.map((breadcrumb: any, index: any) => (
                    <span key={index}>
                      {breadcrumb.url ? (
                        <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                      ) : (
                        <span>{breadcrumb.label}</span>
                      )}
                      {index < breadcrumbs.length - 1 && <span className="separator">/</span>}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="booking-filter mb-4">
            <div className="row g-3">
              <div className="col-lg-9">
                <div className="row g-3">
                  <div className="col-lg-3">
                    <select className="form-select" value={selectedAvatar || ''} onChange={(e) => setSelectedAvatar(parseInt(e.target.value) || null)}>
                      <option value="">Select Name of Ideal Client</option>
                      {distinctAvatars.map((avatar) => (
                        <option key={avatar.user_avatar_id} value={avatar.user_avatar_id}>
                          {avatar.avatar_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-3">
                    <select className="form-select" value={selectedTemplate || ''} onChange={(e) => setSelectedTemplate(parseInt(e.target.value) || null)}>
                      <option value="">Select Copy Template</option>
                      {templates.map((template) => (
                        <option key={template.id} value={template.id}>
                          {template.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-3">
                    <select className="form-select" value={liked || ''} onChange={(e) => setLiked(parseInt(e.target.value) || null)}>
                      <option value="">Only show liked Result</option>
                      <option value="1">Yes</option>
                      <option value="2">No</option>
                    </select>
                  </div>
                  <div className="col-lg-3">
                    <select className="form-select" value={status || ''} onChange={(e) => setStatus(e.target.value)}>
                      <option value="">Select Status</option>
                      <option value="1">Discard</option>
                      <option value="0">Saved</option>
                      {/* Add more status options as needed */}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 text-end">
                <button className="btn btn-primary primary-filled-button py-2 px-3" onClick={handleFilterChange}>
                  Apply Filter
                </button>
              </div>
            </div>
          </div>

          {/* Display filtered results */}
          <div className="row g-4">
            {visibleItems.length > 0 ? (
              visibleItems.map((entry) => (
                <div key={entry.id} className="col-md-12">
                 <Link to={`/history-output/${entry.id}`} className="avatar-card-item" style={{ textDecoration: 'none' }}>
                  <div className="icon-box-circle">
                    <img src={icon} alt="image" title="" />
                  </div>
                  <h4>{entry.title}</h4>
                  <p className="mb-0 font-light-muted">{entry.avatar_name} <span className="mx-4"> - </span> {entry.created_at_history}</p>
                </Link>
                </div>
              ))
            ) : (
              <div>No results found.</div>
            )}

            {visibleItems.length < userAvatarHistory.length && (
              <div className="load-more text-center mt-4">
                <button  className="btn btn-primary primary-outlined-button py-2 px-4" onClick={handleLoadMore}>
                  Load More
                </button>
              </div>
            )}
          </div>
          
        </div>
      </div>
    </section>
  );
};

export default ListHistory;
